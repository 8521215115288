@import "~antd/dist/antd.less";
@primary-color: #0A1746;
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.login-container {
    width: 100%;
    height: 100%;
}
#root {
    height: 100%;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.full-width {
    width: 100%;
}
.m-b-0 {
    margin-bottom: 0px;
}
.m-b-10 {
    margin-bottom: 10px;
}
.ant-layout-header {
    padding: 0 15px;
}
.admin-layout .ant-layout-header {
    padding: 0 15px;
    margin-left: 200px;
}
.logo {
    height: 64px;
    color: #fff;
    margin-bottom: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 5px;
}
.ant-layout {
    padding: 0px !important;
}
.ant-layout-header {
    background: #ffffff;
}
.m-r-8 {
    margin-right: 8px;
}
.p-h-5 {
    padding: 0 5px;
}
.p-v-5 {
    padding: 5px 0px;
}
.m-h-5 {
    margin: 0 5px;
}
.m-v-5 {
    margin: 5px 0px;
}
.m-b-10 {
    margin-bottom: 10px;
}
.text-primary {
    color: @primary-color;
}
.bg-primary {
    background-color: @primary-color;
}
.site-badge-count-4 .ant-badge-count {
    background-color: #fff;
    color: #999;
    box-shadow: 0 0 0 1px #d9d9d9 inset;
}
.diamondSearchPage {
    .ant-upload-list {
        display: none;
    }
}
.text-right {
    text-align: right;
}
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}
.header-row {
    height: 35px;
    background-color: #efefef;
    position: relative;
    padding: 5px 10px;
    .bottom-arrow {
        position: absolute;
        bottom: -9px;
        height: 10px;
        width: 10px;
        left: 30px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #efefef;
    }
}
.filter-row {
    .filter-diamond-box {
        margin: 5px 5px 5px 0px;
    }
}

.ant-layout-content {
    background-color: #ffffffb8 !important;
}
.df {
    display: flex !important;
}
.asc {
    align-self: center;
}

.fdc {
    flex-direction: column;
}
.js {
    justify-content: flex-start;
}
.je {
    justify-content: flex-end;
}
.je {
    justify-content: flex-end;
}
.jc {
    justify-content: center;
}
.jsb {
    justify-content: space-between;
}
.asb {
    justify-content: space-between;
}
.jsa {
    justify-content: space-around;
}
.asa {
    justify-content: space-around;
}
.as {
    align-items: flex-start;
}
.am {
    align-items: center;
}
.ab {
    align-items: flex-end;
}
.fwn {
    flex-wrap: nowrap;
}
.fg1 {
    flex-grow: 1;
}

//chatwoot start
.chat-card {
    .ant-layout-header {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
    .ant-card-body {
        padding: 0px !important;
    }
    .chat-layout {
        height: ~"calc(100vh - 75px)";
        .chat-siderbar {
            background-color: #fff;
            min-width: 350px !important;
            width: 350px !important;
            border-right: 1px solid #f0f0f0;
            padding: 5px;
            .ant-form-item {
                margin-bottom: 5px !important;
                label {
                    font-weight: bold;
                }
            }
            .active-thread {
                background-color: #e6e6e6;
                padding: 6px 8px !important;
            }
            .inactive-thread {
                padding: 6px 8px !important;
            }
            .ant-list-item {
                align-items: flex-start;
            }
            .ant-list-item-meta-avatar {
                align-self: center;
            }
        }
    }
}

.m-t-5 {
    margin-top: 5px;
}
.chat-list .ant-list-items {
    overflow-y: auto;
    height: 66vh;
}
.chat-conversation-header {
    .ant-list-item {
        padding: 0px;
    }
    .ant-list-item-meta-avatar {
        display: flex;
    }
    .call-button {
        webkit-transform: rotateX(180deg);
        transform: rotateY(180deg);
    }
}

.chat-conversation {
    .scrollable {
        overflow: auto;
        height: ~"calc(100vh - 200px)";
        padding: 20px;
        .message-card {
            margin-bottom: 5px;
            border-radius: 6px 2px 6px 3px;
            padding: 5px;
            max-width: 350px;
            .message-time {
                color: #00000073;
                font-size: 0.7rem;
            }
        }
        .card-right {
            background-color: #dcf8c6;
            text-align: right;
        }
        .card-left {
            background-color: bisque;
            text-align: left;
        }
    }
}
.chat-conversation-footer {
    padding: 5px 10px !important;
    height: 68px;
    position: relative;
}
.attached-files {
    z-index: 1;
    position: absolute;
    top: -40px;
    width: 98%;
    background: #efefef;
}
.attachment-icon {
    font-size: 24px;
}
.ant-upload-list {
    display: none;
}
.layout-fill {
    width: 100%;
    height: 100%;
}
//chatwoot start
.cursor-pointer{
    cursor: pointer;
}